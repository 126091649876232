import $ from 'jquery';
import '@selectize/selectize';
let init = ($scope)=>{
	$scope.find('select.form-control').each(function(){
		
		$(this).selectize({
			placeholder: $(this).attr('data-placeholder'), 
		})
	})

}
$(function(){
	init($('body'))
	$(document).on('input.wf.refresh', function(e){
		if (!e.target.selectize){
			return;
		}
		if ($(e.target).attr('disabled')){
			e.target.selectize.disable();
		}
		else{
			e.target.selectize.enable();

		}
	});
	$('body').on('dom.refresh', '*',  function(){
		init($(this));
	})
	$(document).on('select2:open', () => {
		document.querySelector('.select2-search__field').focus();
	});

})